import { toHTML } from "@portabletext/to-html";
import { HeroProps } from "@bluebottlecoffee/design-system";
import { Hero as Schema } from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageProps } from "./image";
import { toVideoProps } from "./video";

function validateData(data: Schema, lang: string) {
  if (!data) return;

  const headline = data?.headline?.[lang];
  if (data.image && data.image.altText && !data.image.source) {
    throw new Error(
      `The Image on${
        typeof headline === "string" ? ` ${headline}` : ""
      } Hero needs a source`,
    );
  }
  if (data.image && data.image.source && !data.image.altText)
    throw new Error(
      `The Image on ${
        typeof headline === "string" ? ` ${headline}` : ""
      } Hero needs alt text`,
    );
}

function isResourceVideo(image: any): boolean {
  return image.source.resource_type === "video";
}

export function toHeroProps(
  data: Schema,
  region: string,
  lang: string,
): HeroProps {
  validateData(data, lang);

  return {
    ...(data.ctas && {
      links: data.ctas.map((cta) => toLinkProps(cta, region, lang)),
    }),
    ...(data.headline && { text: toHTML(data.headline[lang]) }),
    ...(data.image &&
      (isResourceVideo(data.image)
        ? { video: toVideoProps(data.image, lang) }
        : { image: toImageProps(data.image, lang) })),
    centered: data.alignment === "center",
    ...(data.narrowHeight && { narrowHeight: data.narrowHeight }),
    darkTheme: data.colorTheme === "dark",
  };
}
