import { toHTML } from "@portabletext/to-html";
import { CarouselInlineCaptionProps } from "@bluebottlecoffee/design-system";
import { CarouselInlineCaption as Schema } from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageResponsiveWithCaptionProps } from "./image";

export function toCarouselInlineCaptionProps(
  data: Schema,
  region: string,
  lang: string,
): CarouselInlineCaptionProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.cta?.text && { cta: toLinkProps(data.cta, region, lang) }),
    ...(data.heading && {
      heading: toHTML(data.heading[lang]),
    }),
    title: data.title[lang],
    images: data.images.map((i) => toImageResponsiveWithCaptionProps(i, lang)),
  };
}
