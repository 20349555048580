import { FlavorProfileProps } from "@bluebottlecoffee/design-system";
import { FlavorProfile as Schema } from "../sanity-schema";

export function toFlavorProfileProps(
  data: Schema,
  locale: string,
  bgColor?: FlavorProfileProps["bgColor"],
): FlavorProfileProps {
  return {
    ...(bgColor && { bgColor }),
    tastingNotes: data.tastingNotes[locale],
    tasteScales: data.tasteScales.map((s) => ({
      scaleTitle: s.scaleTitle[locale],
      minValueLabel: s.minValueLabel[locale],
      maxValueLabel: s.maxValueLabel[locale],
      tasteValue: s.tasteValue,
    })),
  };
}
