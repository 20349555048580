import { toHTML } from "@portabletext/to-html";
import { EditorialFeatureProps } from "@bluebottlecoffee/design-system";
import { EditorialFeature as Schema } from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageResponsiveProps } from "./image";

export function toEditorialFeatureProps(
  data: Schema,
  region: string,
  lang: string,
): EditorialFeatureProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.cta?.text && { cta: toLinkProps(data.cta, region, lang) }),
    ...(data.subtext && { subText: toHTML(data.subtext[lang]) }),
    labels: data.labels.map((l) => l[lang]),
    image: toImageResponsiveProps(data.image, lang),
  };
}
