import { toHTML } from "@portabletext/to-html";
import { EditorialIconBlocksProps } from "@bluebottlecoffee/design-system";
import { EditorialIconBlock as Schema } from "../sanity-schema";
import { toEditorialIconProps } from "./editorial-icon";

export function toEditorialIconBlocksProps(
  data: Schema,
  lang: string,
): EditorialIconBlocksProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.headline && { headline: toHTML(data.headline[lang]) }),
    ...(data.caption && { caption: toHTML(data.caption[lang]) }),
    editorialIcons: data.editorialIcons?.map((i) =>
      toEditorialIconProps(i, lang),
    ),
  };
}
