import {
  DetailOverviewCopyBlockProps,
  DetailPageOverviewProps,
  GridKeyValueListProps,
} from "@bluebottlecoffee/design-system";
import { toHTML } from "@portabletext/to-html";
import { DetailPageOverview as Schema } from "../sanity-schema";
import { toBulletedKeyValue } from "./bulleted-key-value";

export function toDetailPageOverviewProps(
  data: Schema,
  region: string,
  lang: string,
): DetailPageOverviewProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.detailOverviewCopyBlock && {
      detailOverviewCopyBlock: {
        heading: data.detailOverviewCopyBlock.heading[lang],
        description: toHTML(data.detailOverviewCopyBlock.description?.[lang]),
        isH1: data.detailOverviewCopyBlock.isH1,
      } as DetailOverviewCopyBlockProps,
    }),
    ...(data.keyValues && {
      keyValues: {
        keyValues: data.keyValues.map((kv) =>
          toBulletedKeyValue(kv, region, lang),
        ),
      } as GridKeyValueListProps,
    }),
    appPlayLinks: null, // removed entirely: https://app.shortcut.com/bluebottlecoffee/story/10599/new-website-brew-guides-detail-page
  };
}
