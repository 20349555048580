import { EditorialIconProps } from "@bluebottlecoffee/design-system";
import { toHTML } from "@portabletext/to-html";
import { EditorialIcon as Schema } from "../sanity-schema";
import { toImageProps } from "./image";

export function toEditorialIconProps(
  data: Schema,
  lang: string,
): EditorialIconProps {
  return {
    ...(data.name && { name: toHTML(data.name[lang]) }),
    ...(data.description && { description: toHTML(data.description[lang]) }),
    ...(data.icon && { icon: toImageProps(data.icon, lang) }),
  };
}
