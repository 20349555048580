import { toHTML } from "@portabletext/to-html";
import { CategoryCardProps } from "@bluebottlecoffee/design-system";
import { CategoryCard as Schema } from "../sanity-schema";
import { toImageProps } from "./image";
import { toLinkProps } from "./link";

export function toCategoryCardProps(
  data: Schema,
  region: string,
  locale: string,
  bgColor?: CategoryCardProps["bgColor"],
): CategoryCardProps {
  return {
    ...(bgColor && { bgColor }),
    heading: data.heading[locale],
    link: toLinkProps(data.url, region, locale),
    subheading: toHTML(data.subheading[locale]),
    image: toImageProps(data.image, locale),
  };
}
