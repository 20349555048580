import { toHTML } from "@portabletext/to-html";
import { ArticleCardCarouselProps } from "@bluebottlecoffee/design-system";
import { ArticleCardCarousel as Schema } from "../sanity-schema";
import { toArticleCardProps } from "./article-card";
import { toLinkProps } from "./link";

export function toArticleCardCarouselProps(
  data: Schema,
  region: string,
  lang: string,
): ArticleCardCarouselProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.cta &&
      data.cta.text && { cta: toLinkProps(data.cta, region, lang) }),
    headline: toHTML(data.headline[lang]),
    articleCards: data.articleCards.map((c) =>
      toArticleCardProps(c, region, lang),
    ),
  };
}
