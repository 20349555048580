import { BulletedKeyValue } from "@bluebottlecoffee/design-system/dist/lib/types";
import { toHTML } from "@portabletext/to-html";
import { KeyValue } from "../sanity-schema";

export function toBulletedKeyValue(
  data: KeyValue,
  region: string,
  lang: string,
): BulletedKeyValue {
  return {
    key: data.key[lang],
    value: toHTML(data.value[lang]),
  };
}
