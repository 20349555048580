import {
  ArticleCardCarousel,
  BulletedKeyValueList,
  CarouselInlineCaption,
  CategoryCarousel,
  CopyColumn,
  DetailPageOverview,
  EditorialFeature,
  EditorialFullBleed,
  EditorialIconBlocks,
  Hero,
  Quote,
  TwoUp,
  VimeoPlayer,
} from "@bluebottlecoffee/design-system";
import { toArticleCardCarouselProps } from "./transformers/article-card-carousel";
import { toCategoryCarouselProps } from "./transformers/category-carousel";
import { toCarouselInlineCaptionProps } from "./transformers/carousel-inline-caption";
import { toCopyColumnProps } from "./transformers/copy-column";
import { toEditorialFeatureProps } from "./transformers/editorial-feature";
import { toEditorialFullBleedProps } from "./transformers/editorial-full-bleed";
import { toEditorialIconBlocksProps } from "./transformers/editorial-icon-blocks";
import { toQuoteProps } from "./transformers/quote";
import { toTwoUpProps } from "./transformers/two-up";
import { toDetailPageOverviewProps } from "./transformers/detail-page-overview";
import { toVimeoPlayerProps } from "./transformers/vimeo-player";
import { toBulletedKeyValueListProps } from "./transformers/bulleted-key-value-list";
import { ElementOf } from "./utils";
import {
  BrewGuide,
  BrewGuidesPage,
  Cafe,
  CafesPage,
  HelpPage,
  Homepage,
  InstantEspresso,
  OurCoffee,
  OurStory,
  OurValues,
  Product,
  SubscriptionPage,
  Sustainability,
} from "./sanity-schema";
import { toHeroProps } from "./transformers/hero";

type ModuleElementType =
  | ElementOf<BrewGuide["modules"]>
  | ElementOf<BrewGuidesPage["modules"]>
  | ElementOf<Cafe["modules"]>
  | ElementOf<CafesPage["modules"]>
  | ElementOf<HelpPage["modules"]>
  | ElementOf<Homepage["modules"]>
  | ElementOf<InstantEspresso["modules"]>
  | ElementOf<OurCoffee["modules"]>
  | ElementOf<OurStory["modules"]>
  | ElementOf<OurValues["modules"]>
  | ElementOf<Product["modules"]>
  | ElementOf<SubscriptionPage["modules"]>
  | ElementOf<Sustainability["modules"]>;

export default function renderModule(
  module: ModuleElementType,
  region: string,
  lang: string,
) {
  try {
    switch (module._type) {
      case "articleCardCarousel": {
        return (
          <ArticleCardCarousel
            {...toArticleCardCarouselProps(module, region, lang)}
          />
        );
      }
      case "bulletedKeyValueList": {
        return (
          <BulletedKeyValueList
            {...toBulletedKeyValueListProps(module, "cream", region, lang)}
          />
        );
      }
      case "carouselInlineCaption": {
        return (
          <CarouselInlineCaption
            {...toCarouselInlineCaptionProps(module, region, lang)}
          />
        );
      }
      case "categoryCarousel": {
        return (
          <CategoryCarousel
            {...toCategoryCarouselProps(module, region, lang)}
          />
        );
      }
      case "copyColumnFreeFlow": {
        return <CopyColumn {...toCopyColumnProps(module, region, lang)} />;
      }
      case "detailPageOverview":
        return (
          <DetailPageOverview
            {...toDetailPageOverviewProps(module, region, lang)}
          />
        );
      case "editorialFeature": {
        return (
          <EditorialFeature
            {...toEditorialFeatureProps(module, region, lang)}
          />
        );
      }
      case "editorialFullBleed": {
        return (
          <EditorialFullBleed
            {...toEditorialFullBleedProps(module, region, lang)}
          />
        );
      }
      case "editorialIconBlock": {
        return (
          <EditorialIconBlocks {...toEditorialIconBlocksProps(module, lang)} />
        );
      }
      case "hero": {
        return <Hero {...toHeroProps(module, region, lang)} />;
      }
      case "quote":
        return <Quote {...toQuoteProps(module, lang)} />;
      case "twoUp":
        return <TwoUp {...toTwoUpProps(module, region, lang)} />;
      case "vimeoPlayer":
        return <VimeoPlayer {...toVimeoPlayerProps(module)} />;
      default: {
        throw Error(`Module [${module._type}] did not have a generator.`);
      }
    }
  } catch (e) {
    console.debug(e);
    console.debug(module);
    throw new Error(
      `Rendering module ${module._type} failed with ${e.message},`,
    );
  }
}
