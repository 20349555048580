import { toHTML } from "@portabletext/to-html";
import { ArticleCardProps } from "@bluebottlecoffee/design-system";
import { ArticleCard as Schema } from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageProps } from "./image";

export function toArticleCardProps (
    data:Schema, 
    region:string, 
    lang:string
):ArticleCardProps {
    return {
        heading: data.heading[lang],
        description: toHTML(data.description[lang]),
        cta: toLinkProps(data.cta, region, lang),
        image: toImageProps(data.image, lang)
     }
}