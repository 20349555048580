import { Video as VideoProps } from "@bluebottlecoffee/design-system/dist/lib/types";
import { ImageType } from "./image";

export function toVideoProps(data: ImageType, lang: string): VideoProps {
  const video =
    data._type === "imageResponsive" ||
    data._type === "imageResponsiveWithCaption"
      ? data.desktop
      : data;

  const altText = video?.altText?.[lang];

  if (!video.source)
    throw new Error(
      altText
        ? `Video with alt text "${altText}" has not been selected. Either remove the alt text or select a video.`
        : "Video does not contain a source",
    );

  const version = video.source?.version;
  const posterUrlParts = video.source?.url.split(`v${version}`);
  const posterPrefix = posterUrlParts[0];
  const posterSuffix = posterUrlParts[1].split(".")[0];
  const posterUrl = `${posterPrefix}so_0/v${version}${posterSuffix}.jpg`;

  return {
    description: altText ?? "",
    poster: posterUrl,
    src: video.source?.url,
  };
}
